<template>
	<div class="post">
		<Topq></Topq>
		<div class="post-content">
			<div class="main">
			<div class="main-title">
				<div class="wanted-top">
					<div class="top-wanted">你想要找什么工作?</div>
					<div class="top-write">
						完善详细信息填写，可获得更多精准高薪工作机会
					</div>
				</div>
			</div>	
			<div class="wanted-state">
				<span>求职类型：</span>
				<div class="wanted-state-main">
					<el-select v-model="qiuvalue" placeholder="请选择">
						<el-option v-for="item in zhioptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>求职状态：</span>
				<div class="wanted-state-main">
					<el-select v-model="statevalue" placeholder="请选择">
						<el-option v-for="item in stateoptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state">
				<span class="city">期望工作城市：</span>
				<div class="wanted-state-main">
					<area-select type='code' :level='2' v-model="selected" :data="pcaa"></area-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>期望行业：</span>
				<div class="wanted-state-main">
					<el-select v-model="hangvalue" placeholder="请选择" @change="hang">
						<el-option v-for="(item,index) in industryInfos" :key="index" :label="item.industryName"
							:value="item.industryId">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>职位类型：</span>
				<div class="wanted-state-main">
					<el-select v-model="zhivalue" placeholder="请选择">
						<el-option v-for="(i,j) in categories" :key="j" :label="i.categoryName" :value="i.id">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>职位名称：</span>
				<div class="wanted-state-main">
					<div class="channel">
						<el-input v-model="zhiname" placeholder="请输入职位名称"></el-input>
					</div>
				</div>
			</div>
			<div class="wanted-state">
				<span>薪资要求：</span>
				<div class="wanted-state-main">
					<el-select v-model="xinvalue" placeholder="请选择">
						<el-option v-for="item in xinoptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="post-button" @click="submit">
				保存
			</div>
		</div>		
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import $http from '../common/api/axios.js'
	import {
		pubExpectPost,
		industryInfo,
		getExpectPost
	} from '../common/api/api.js'
	import {
		pcaa
	} from 'area-data';
	import Topq from '../components/common/Topq.vue'
	import Footer from './common/Footer.vue'
	export default {
		data() {
			return {
				selected: [], // 接收数据的对象
				pcaa: pcaa,
				zhioptions: [{
					value: '0',
					label: '全职'
				}, {
					value: '1',
					label: '实习全职'
				}, {
					value: '2',
					label: '实习兼职'
				}, {
					value: '3',
					label: '兼职'
				}],
				stateoptions: [{
					value: '0',
					label: '离职-随时到岗'
				}, {
					value: '1',
					label: '在职-月内到岗'
				}, {
					value: '2',
					label: '在职考虑机会'
				}, {
					value: '3',
					label: '在职-暂不考虑'
				}],
				zhiname: '',
				qiuvalue: '',
				statevalue: '',
				typevalue: '',
				namevalue: '',
				xinvalue: '',
				industryInfos: [],
				categories: [],
				zhivalue: '',
				hangvalue: '',
				xinoptions: [{
						value: '0',
						label: '0-5000'
					}, {
						value: '1',
						label: '5000-8000'
					}, {
						value: '2',
						label: '8000-11000'
					}, {
						value: '3',
						label: '11000-15000'
					}, {
						value: '4',
						label: '15000-20000'
					}, {
						value: '5',
						label: '20000-30000'
					}, {
						value: '6',
						label: '30000-50000'
					},
					{
						value: '7',
						label: '50000以上'
					}
				],
			}
		},
		created() {
			// this.selected[0] = pcaa[86][110000]; //res.data.addrprovinceid省对应的id
			// this.selected[1] = pcaa[110000][110100]; //res.data.addrcityid省对应的id
			// this.selected[2] = pcaa[110100][110101]; //res.data.addrcountyid省对应的id
			this.$store.dispatch('initDic').then(() => {
				let that = this
				$http.request({
					url: industryInfo,
				}).then((res) => {
					that.industryInfos = res.data.industryInfos
					let id = this.$route.query.id
					if (id) {
						$http.request({
							url: getExpectPost,
							data: {
								"postId": id,
							}
						}).then((res) => {
							that.qiuvalue = res.data.type.toString()
							that.statevalue = res.data.status.toString()
							let provinceId = res.data.province
							let cityId = res.data.city
							let districtId = res.data.district;
							that.selected = [provinceId, cityId, districtId]
							that.hangvalue = res.data.industry					
							that.categories=that.industryInfos[that.hangvalue-1].categories
							that.zhivalue=res.data.category
							that.zhiname = res.data.postTitle
							that.xinvalue = res.data.salary.toString()
						}).catch(() => {
					
						});
					}
				}).catch(() => {

				});
			})
		},
		mounted() {
		
			
		},
		components: {
			Topq,
			Footer

		},
		methods: {
			hang(e) {
				this.zhivalue = ''
				let num = e - 1
				this.categories = this.industryInfos[num].categories
			},
			submit() {
				let id = this.$route.query.id
				let that = this
				$http.request({
					url: pubExpectPost,
					data: {
						"postId": id,
						"category": that.zhivalue,
						"city": that.selected[1],
						"industry": that.hangvalue,
						"province": that.selected[0],
						"district": that.selected[2],
						"salary": that.xinvalue,
						"status": that.statevalue,
						"type": that.qiuvalue,
						"postTitle": that.zhiname
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: '成功!',
							type: 'success',
							duration:'2000'
						});
						that.$router.push('./pj-wanted')
					}
				}).catch(() => {

				})
			}
		}
	}
</script>

<style scoped>
	.main-title{
		width: 100%;
		font-family: PingFang SC;
	}
	.wanted-top {
		box-sizing: border-box;
		margin-left: .4rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.top-write{
		margin-bottom: .62rem;
		font-size: .14rem;
		font-weight: 400;
		color: #333333;
	}
	.top-wanted {
		margin-bottom: .1rem;
		font-size: .24rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: 30px;
	}
	
	.top-num span {
		color: #762FEF;
	}
	.post-button {
		margin-bottom: .3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 5.67rem;
		height: .52rem;
		background: #E41D1D;
		border-radius: 6px;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	/deep/.area-select-wrap .area-select {
		margin-left: 0;
	}

	/deep/.area-selected-trigger {
		display: flex !important;
		align-items: center !important;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	.city{
		margin-left: -28px;
	}
	/deep/.area-select.medium {
		width: 2.88rem !important;
		height: .54rem !important;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.post {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.post-content {
		padding: 0 3.6rem;
		width: 19.2rem;
		flex: 1;

	}
	.main{
		padding-top: .7rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 12rem;
		border: 1px solid rgba(172, 172, 172, 0.2);
		box-sizing: border-box;
	}
	.wanted-state {
		display: flex;
		margin-bottom: .5rem;
	}

	.wanted-state span {
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: .54rem;
	}

	/deep/.el-input__inner {
		text-align: center!important;
		height: .54rem !important;
		font-size: .16rem !important;
		font-family: PingFang SC !important;
		font-weight: 400 !important;
		color: #333333 !important;
	}

	input.el-input__inner::-webkit-input-placeholder {
		color: #CCCCCC !important;
		font-weight: 400 !important;
	}

	input.el-input__inner::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #CCCCCC !important;
		font-weight: 400 !important;
	}

	input.el-input__inner:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #CCCCCC !important;
		font-weight: 400 !important;
	}

	input.el-input__inner:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #CCCCCC !important;
		font-weight: 400 !important;
	}

	.el-input__suffix {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/deep/.el-input__icon {
		line-height: .54rem !important;
	}

	/deep/.el-input {
		
		font-size: .16rem !important;
		width: 8.8rem !important;
	}

	/deep/.el-select .el-input .el-select__caret {
		color: #333333;
	}

	.el-select-dropdown__item {
		font-size: .16rem !important;
		font-family: PingFang SC !important;
		font-weight: 400 !important;
		color: #333333 !important;
		line-height: .30rem !important;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border: 1px solid #762FEF !important;
	}
</style>
